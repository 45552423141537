@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: local('LibreBaskerville-Bold'),
         url('../../fonts/LibreBaskerville-Bold.ttf') format('truetype');
       
}



.skj{
    height: fit-content;
}
.skj_bg{
  width:100%;
   height: 450px;
  position: absolute;
 
  
}



.skj_programs{
    height:fit-content;

 
   
}

.skj_heading{
    text-align: center;
    margin: 50px;
    margin-left: 130px;
    font-family: LibreBaskerville-Bold;
    color: rgb(5,5,100);
    margin-top: 130px;

   
    

 
}
.skj_contents{
    margin: 50px;
text-align: justify;
position: relative;
display: flex;
flex-direction: row;





}

.skj_intro{
    font-size:15px;
    width: 70%;
    height: fit-content;
    font-weight:700;
    z-index: 0.5;
    
    padding-left: 100px;
  
    background-image: url('../../images/SKJ/skj_bg_3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
   

  
    


    
}


.skj_para11{
padding:75px;
margin-left: 110px;
margin-bottom: 60px;
width: 80%;


}



.skj_list{
    font-size: medium;
    margin-top: 20px;
    width: 70%;
    margin-left: 20px;
   

background-image: url('../../images/orange4.jpg');
background-repeat: no-repeat;
background-size:cover;

padding: 50px;
border-radius: 10px;

}


.skj_list li{
    margin-top: 33px;
    width: 90%;
    text-align: justify;
}

.skj_list li::marker{
    font-weight: bold;
}
.skj_list h4{
    font-weight: 700;
}
.skj_list p{
    font-weight: 500;
    margin-top: 30px;
}
.skj_img1 img{
    width:100%;
    margin-top: 20px;
    border-radius: 20px;
    margin-left: 40px;
}

.skj_img{
margin-left: 50px;
}

.car_img {
    width:80%;
    border-radius: 10px;
    margin-left: 70px;
 

   
    }

    .image2{
        width: 600px;
        height: 100px;;
    }
    
    .skj_part2{
    
     
     position: relative;
     display: flex;
     flex-direction: row;
     height: fit-content;
    }
    
    .skj_img{
        margin-top: 20px;
       
        /*width: 70%;*/
        height:fit-content;
        width: 60%;


       
        
        
    }
    .skj_img1{
        width: 60%;
    }

    .skj_para11 p{
        margin-left: 10px;
    }
 

  
    @media screen  and (max-width:1200px){
        .skj_programs{
            height:fit-content;

        
         
           
        }
            
    }
    @media screen  and (max-width:992px){
        .skj{
            margin-top: 200px;
        }
        .skj_heading{
            margin-left: 50px;
            font-size: large;
        }
        .skj_contents{
            display: flex;
            flex-direction: column;
            margin: 10px;
        }
        .skj_part2{
            display: flex;

            flex-direction: column;
        }
            

        .skj_intro{
            width: 80%;
            padding-left:100px;
        }
            .skj_para11{
                padding: 5px;
                margin-top: 40px;
                
                width: 70%;
            }
            .skj_img1 {
                width:90%;
                
            }
            .skj_img1 img{
                margin-left: 10px;
            }
           .skj_list{
            width: 90%;
           }
    
           .skj_list ol{
            width:100%;
            padding: 0;
           }
           .skj_img {
            width:90%;
            margin-left: 0;
         
        
           
            }
            .skj_car{
                height: fit-content;
                margin-top: 40px;
            }
    }

    @media screen  and (max-width:789px){
        .skj_intro{
            width: 100%;
            padding-left:100px;
            
        }
            .skj_para11{
                padding: 5px;
                margin-top: 40px;
                
                width: 80%;
            }
    }
    
    @media screen  and (max-width:600px){
        .skj_intro{
            width: 100%;
            padding-left:80px;
            font-size: 13px;
        }
            .skj_para11{
                padding: 5px;
                margin-top: 40px;
                
                width: 70%;
            }
    }
    
    @media screen  and (max-width:550px){
        .skj_intro{
           
            padding-left:50px;
            font-size: 10px;
        }
            .skj_para11{
                padding: 5px;
                margin-top: 40px;
                padding-left: 5px;
                width: 60%;
            }
    }

    @media screen  and (max-width:350px){
        .skj_intro{
            width:100%;
            padding-left:5px;
            font-size: 11px;
            margin-left: 2px;
        }
            .skj_para11{
                padding: 5px;
                margin-top: 40px;
                text-align: justify;
                
                
                width: 70%;
            }
    }
 