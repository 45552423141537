/*@font-face {
    font-family: 'ChrustyRock-ORLA';
    src: local('ChrustyRock-ORLA'),
         url('../../fonts/ChrustyRock-ORLA.ttf') format('truetype');
       
}

.focus{
    height:fit-content;
    margin-top: 120px;   
}


.focus p{
    
    
    font-family: LibreBaskerville-Bold;
    color: rgb(5,5,100);
    font-weight:900;
    padding: 20px;
    font-size:xx-large;
   text-align: center;
   
    

}


.Thiruvathira_text{

 width:50%;
 text-align: center;
 margin: 20px;


}

.Thiruvathira_text h2{
color: rgb(79, 121, 8);
font-size:x-large;
font-weight: bold;

}
.Thiruvathira_text h1{
    font-family:ChrustyRock-ORLA;
    color: rgba(122, 6, 84, 0.952);
    font-size: xx-large;
    font-weight: bolder;
}






.focus_body{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-left: 30px;
    
 
}


.focus_img1 img{
  width: 90%;
  border-radius: 20%;
  margin-left: 20px;
}
.focus_img2 a{
    margin-top: 50px;
    width:300px;
    height: 50px;
    font-size: medium;
    font-weight: bold;
    padding: 10px;
}
.focus_img2 img{
    width: 650px;
}
.Calender_div{
    align-items: center;
}*/


.cal_img img{
    width:80%;
    margin-left: 100px;
    margin-top: 100px;
}


@media screen  and (max-width:789px){
    .cal_img img{
        width: 100%;
        margin-left: 10px;
        margin-top: 180px;
    }
}

@media screen  and (max-width:600px){
    .cal_img img{
        width: 100%;
        margin-left: 10px;
    }
}