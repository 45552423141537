@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: local('LibreBaskerville-Bold'),
         url('../../fonts/LibreBaskerville-Bold.ttf') format('truetype');
       
}


.Contact{
    height:fit-content;
    display: flex;
    flex-direction: row;
   
}

.Contact_content{
  width:30%; 
  margin: 50px; 
  font-size: medium;
  margin-top: 150px;
  margin-left: 200px;

}
.Contact_content h1{
    color: #b65f0f;
    font-weight: 900;
}
.Contact_content h2{
    color: #61360e;
    font-weight: 800;
    margin-top: 250px;
}

.Contact-para{
    margin-top: 30px;
    font-weight:500;
    font-size: medium;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 400px;
    height: 400px;
    text-align: justify;
    color: rgb(5, 5, 100);

}
.contact_form{
    margin-top: 230px;
    margin-left: 20px;
    margin-right: 100px;
    text-align: left;
    width: 400px;

}

.contact_form h3{
    font-size: 24px;
    margin-bottom: 20px;
    color: black;
}
.form-group{
    margin-bottom: 20px;
}

input,textarea{
    width: 100%;
    padding:12px;
    border-radius: 8px;
    border:0.01px solid black;
    background-color: #ffffff;
    color:#333
}
input:focus,textarea:focus{
    outline:none;
    box-shadow: 0 0 8px #bbb;
}
button{
    display: inline-block;
    padding: 12px 24px;
    background-color: #4caf50;
    color:#fff;
    border :none;
    cursor:pointer;
    transition:0.3s ease ;

}

buttton:hover{
    background-color: #45a049;
}

.contactUs_link{
color: blue;
font-style: italic;
}

.bgIcon_contact{
    width: 30%;
}



/*------to make the page responsive for diff. screen size*/



@media screen  and (max-width:1200px) {

}
@media screen  and (max-width:992px) {
    .Contact{
        height:fit-content;
        display: flex;
        flex-direction: column;
       
        margin-left: 10px;
       
    }
  
    .contact_form{
        margin-top: 5px;
        margin-left: 300px;
        width: 400px;
    
    }
}
@media screen  and (max-width:789px) {
    .contact_form{
        margin-top: 5px;
        margin-left: 100px;
    
        width: 300px;
    
    }
    .Contact_content{
        margin-left: 50px;
    }
      .Contact-para{
        width: 300px;
      }
    
}
@media screen  and (max-width:400px) {
    .contact_form{
        margin-top: 20px;
        margin-left: 50px;
        width: 200px;
    
    }
    .Contact-para  {
       
        width:200px;
        font-size: small;
        margin:0;

    }
    .contactUs_link{
        width: 200px;
        font-size: smaller;
        margin-left: 2px;
        
    }
    .Contact_content{
        margin-left: 5px;
    }
      
}

