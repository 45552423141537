@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: local('LibreBaskerville-Bold'),
         url('../../fonts/LibreBaskerville-Bold.ttf') format('truetype');
}

*,
*::after,
*::before{
    border:0;
    box-sizing: border-box;
}

html{
    font-size: 62.5%;
}

body{
    font-size: 1.6rem;
    overflow-x: hidden;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
    color: ghostwhite;
}

nav{
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 15vh;
    background-color: rgb(244, 166, 48);
    overflow: hidden;
    position: fixed;
   top:0;
   right: 0;
   left: 0;
   z-index: 2;
  
    
    
}
.Nav_begin{
    height:15vh ;
    width: 100%;
    color:rgb(5, 5, 100);
    display: flex;
   
    
    
}



.icon{

   margin-left:10px;
}

.Bg_Heading
{
font-family: LibreBaskerville-Bold;
margin-top: 30px;
font-size:x-large;
width:100%;
margin-left: 20px;
display: flex;
flex-direction: column;
}
.Bg_Heading p{
   font-size:9.5px; 
   font-weight: 500;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Bg_icon_link{

width: 40%;
  
}

.Nav_list{
    margin-left:300px;
    width: 100%;
}

.menu_items{
    display: flex;
    gap: 3rem;
   /* margin-left: 400px;*/
}
.nav__toggler div{
    width: 2rem;
    height:0.2rem;
    margin:0.4rem;
    background-color: ghostwhite;
}


.nav__toggler{
    cursor: pointer;
    display: none;
}

.line1, .line2, .line3 {
    width: 25px;
    height: 5px;
    background-color:white;
    margin: 8px 5px;
    transition: 0.4s;
  }

 @media screen  and (max-width:1200px) {
    .nav__toggler{
        display: block;
        cursor: pointer;
    }
    .menu_items{
        position: fixed;
        top: 12vh;
        right: 0;
        width:40%;
        /*height: 30vh;*/
        background-color:   rgb(244, 196, 48);
        flex-direction: column;
        transform: translateX(100%);
        transition: 0.5s ease-in;
       
    }
   
 }

 .nav__active{
    transform: translateX(0);
 }

 @media screen  and (min-width:300px) and (max-width:480px){
    .nav__toggler{
        display: block;
        cursor: pointer;
    }
    .NavBar{
        width:500px;
        right: 0;
       height: 150px;
        
    }
    .Nav_list{
        margin-left: 30px;
    }
 }
.nav_link{
    text-decoration: none;
    font-size: medium;
    color: rgb(5, 5, 100);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}