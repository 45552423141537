.Gokulam-list {
    

   
 }
 .unit_div{
height: fit-content;
margin-top: 200px;
font-weight: bold;
/*background-image: url('../../images/unit_bg11.jpg');
background-repeat: no-repeat;
background-size: cover;*/


margin-left: 200px;
 }
 .unit-heading{
    margin-left: 350px;
    margin-top: 150px;
 }

 .unit_div table{
    margin-left: 50px;
    padding: 10px;
    width:70%;
    margin-top: 50px;
    
 }
 .unit_div td{
   border: 2px solid orange;
   padding: 20px;
 }

 
 @media screen  and (max-width:1200px){
   .unit-heading{
      margin-left: 350px;
      margin-top: 100px;
   }
   
   .unit_div table{

      width:70%;
      margin-left: 100px;
     
      
   }
   .unit_div td{
      font-size: medium;
    }
  
}
@media screen  and (max-width:789px){
   .unit-heading{
      margin-left: 250px;
      margin-top: 120px;
   }
   .unit_div table{
     
      width:60%;
      margin-left: 50px;
      
   }
   .unit_div td{
      font-size: small;
    }
}
@media screen  and (max-width:600px){
   .unit-heading{
      margin-left: 150px;
      margin-top: 120px;
   }
   .unit_div table{
      
     /* width:30%;*/
      margin-left: 20px;
     
   }
   .unit_div td{
    font-size: 15px;
    }
}
@media screen  and (max-width:580px){
   .unit_div{
      
      /*width:50%;*/
      margin-left: 40px;
     

   }
   .unit_div td{
      font-size: 10px;
      }
}