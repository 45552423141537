.gallery{
    margin-top: 150px;
    text-align: center;
    font-family: LibreBaskerville-Bold;
    color: rgb(5,5,100);
    font-weight:900;
    margin-left: 50px;
    margin-bottom: 50px;
    
    
}
.g_img{
    width: 20%;
    padding: 20px;
}

.lg-react-element{
    column-count: 3;
    column-gap: 2px;
}

.gallery_img{
    max-width: 80%;
    display: block;
    padding: 2px 0px;
    border-radius: 10px;
    transition: transform .2s;
}

.gallery_img:hover{
filter: opacity(0.9);
transform: scale(1.01);
}

.t-img{
    margin-top: 50px;
}
.t_img_inner{
    margin-top: 100px;
}

.t-img.h3{
    margin-bottom: 50px;
}
.kh{
    margin-top: 50px;
}
.skj-h{
    margin-top: 50px;
}