.About-us-div{
   display: flex;
   flex-direction:row;
   font-size: medium;
   margin-top: 120px;
   margin-bottom: 50px;
   

}

.About_para{
  width: 100%;
  margin-left: 100px;
  text-align: justify;
 

}
.About_para h1{
    color:#aa541a;
    font-weight: bold;
}
.About_para h3{
    color:#aa541a;
    font-weight: bold;
}

.About_img{
    width: 60%;
    margin-left: 200px;
    padding : 20px;
    border-radius: 25px;
}
.About-admin-link{
    color: rgb(80, 88, 204);

   
}
.About-admin-link h4{
    font-weight: bold;
}

.About-link-div{
    margin-left:100px;
    
 
}

.About-link-div a{
    text-decoration: none;
}
 


.About_quote{
    margin: 50px;
    color: #e97b14;
    font-weight: 800;
    font-size: large;
    margin-left: 300px;
}

/*------to make the page responsive for diff. screen size*/

@media screen  and (max-width:1200px) {

}

@media screen  and (max-width:992px) {
    .About-us-div{
        display: flex;
        flex-direction:column;
        font-size: medium;
        overflow-x: hidden;
        
        
     
     }
     .About_para{
        width: 80%;
        margin-left: 20px;
        text-align: justify;
       
      
      }

      .About_img{
        width: 60%;
        margin-left: 120px;
       
        border-radius: 25px;
    }
}
@media screen  and (max-width:789px) {
    .About-us-div{
        display: flex;
        flex-direction:column;
        font-size: medium;
        
        
     
     }
     .About_para{
        width: 90%;
        margin-left: 20px;
        text-align: justify;
       
      
      }
      .About_img{
        width: 60%;
        margin-left: 100px;
       
        border-radius: 25px;
    }
}
@media screen  and (max-width:600px) {
    
}