.join{
    
    padding: 20px;
    margin-top: 150px;
    height: fit-content;
    text-align: center;
    
}

.JoinUs-Form{
    height: fit-content;
    width: 100%;
   
}

.JoinUs-Form iframe{
    
    overflow: hidden;
    margin-top: 50px;
}
.join_headings{
    text-align: center;
}



/*------*/


@media screen  and (max-width:678px) {
    .JoinUs-Form iframe{
    
        overflow: hidden;
        margin-top: 50px;
        width: 540px;
    }
}
@media screen  and (max-width:574px) {
    .JoinUs-Form iframe{
    
        overflow: hidden;
        margin-top: 50px;
        width: 440px;
    }
}



@media screen  and (max-width:468px) {
    .JoinUs-Form iframe{
    
        overflow: hidden;
        margin-top: 50px;
        width: 340px;
    }
}







/*.formInput{
    width: 350px;
   
}
.join_form{
    border: 1px solid black;
    width: 450px;
    padding: 20px;
    margin-top: 50px;
  margin-left: 400px;
  align-items: center;
 
    

}
.submit_button{
    margin-top: 20px;
    background-color: orange;
    font-size:medium;
    font-weight: bold;
}
.join_form2{
    width: 500px;
    border: 0.1px solid black;
    border-radius: 5px;
    padding : 50px;
    margin-left: 400px;
    margin-top: 50px;
    background-image: url('../../images/orange.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
}
button{
    margin-left: 150px;
}*/