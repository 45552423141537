@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: local('LibreBaskerville-Bold'),
         url('../../fonts/LibreBaskerville-Bold.ttf') format('truetype');
       
}





.weekly{
    margin-top:50px ;
}
.weekly_classes{

display: flex;
flex-direction: row;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;




}

.weekly_part1{
  
}

.weekly_heading{
 text-align: center;
 margin: 50px;
 margin-left: 60px;
 font-family: LibreBaskerville-Bold;
 margin-top: 120px;
 color: rgb(5,5,100);
}
.weekly_contents{
margin: 0 30px 30px 30px;
text-align: justify;
background-image: url('../../images/orange4.jpg');
background-repeat: no-repeat;
background-size:cover;
width: 600px;
padding: 50px;
border-radius: 10px;

}
.weekly_intro{
font-size: medium;
width: 100%;
font-weight: 500;
}

.weekly_intro h3{
    margin-top: 20px;
    font-weight: 700;
}
.weekly_list{
    font-size: medium;
    margin-top: 20px;
}
.weekly_list li{
    margin-top: 33px;
    width: 90%;
    text-align: justify;
}
.weekly_list li::marker{
    font-weight: bold;
}


.weekly_list h4{
    font-weight: 700;
}
.weekly_list p{
    font-weight: 500;
    margin-top: 30px;
    width: 90%;
}
.weekly_img img{
width: 80%;
height: 50%;
border-radius: 10px;
margin-top: 50px;
}

.weekly_part2{
 margin-left: 50px;
}

.weekly_img{
   
}


@media screen  and (max-width:1200px) {
    .weekly_classes{

        display: flex;
        flex-direction: column;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
}

@media screen  and (max-width:658px) {
    .weekly_contents{
        width: 90%;
        
    }
    .weekly_heading{
        margin-top: 150px;
    }
}