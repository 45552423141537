.Committee_div{
    height: fit-content;
    margin-top: 100px;
    background-image: url('../../images/orange4.jpg');
    background-repeat: no-repeat;
    background-size: cover;

}

.com_img img{
   width:60%;
    margin-left: 250px;
}

@media screen  and (max-width:1200px){
    .com_img img{
        width:60%;
         margin-left: 200px;
     }
}


@media screen  and (max-width:789px){
    .com_img img{
        width:60%;
         margin-left: 150px;
     }
}
@media screen  and (max-width:600px){
    .com_img img{
        width:60%;
         margin-left: 100px;
     }
}

@media screen  and (max-width:350px){
    .com_img img{
        width:60%;
         margin-left: 50px;
     }
}