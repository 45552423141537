.res_main{
    height:fit-content;
    margin-top: 150px;
 
    
    
}


.resources{
   
    display: grid;
    grid-template-columns: auto auto  ;

  
}
.Res_Heading{
    font-size: 30px;
   
    text-align: center;
    font-family: LibreBaskerville-Bold;
    color: rgb(100, 40, 5);
    font-weight:900;
}
.resources_content{
   
}
.resources_content ol{
    list-style-type: disc;
}
.r_card-body a{
color:blue;
}




.r_Activities-card1 {
    padding:30px;
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: fit-content;
    margin:30px;
    text-align: justify;
    align-items: center;
    font-weight: 500;
    width:80%;
    margin-left: 80px;

  
  
}
.r_Activities-card1 a{
    margin-left: 100px;
    width: 100px;
    height: 40px;
    text-align: center;
    font-size: 15px;
}

.r_Activities-card1:hover{
    transform: scale(1.05);   
    cursor: pointer;
   
    
   
}

.r_Activities-card-intro{
    text-align: center;
}

.r_Activities-card-intro h1{
    font-family:  LibreBaskerville-Bold;
    font-weight: bold;
    font-size: 30px;

  
    height: 100px;
    border-radius: 20%;
    align-items: center;
    margin-left: 400px;
    padding: 30px;
   
}
.r_Activities-card-intro p{
    
    color: rgb(5, 5, 100);
    margin: 20px;
    
    font-weight:500;
    font-size: medium;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90%;
    text-align: justify;
    margin-left: 50px;
}


.r_card-text{
    width: 300px;
    font-size:15px;
    margin-top: 10px;
    
}
.r_card-title{
    font-size: 15px;
    text-align: center;
    font-weight: bolder;
    margin-top: 15px;
}

@media screen  and (max-width:1200px) {

    .res_main{
        height:fit-content;
        margin-top: 150px;
     
        
        
    }
    
    .resources{
   
     
        display: grid;
        grid-template-columns: auto  ;
        
    
      
    }
}

@media screen  and (max-width:992px) {
    .res_main{
        margin-top: 250px;
    }
    .resources{
   
        display: grid;
        grid-template-columns: auto  ;
        align-items: center;
    
      
    }
}

@media screen  and (max-width:789px) {
    .resources{
   
        display: grid;
        grid-template-columns: auto  ;
       
    
      
    }
}
@media screen  and (max-width:600px) {
    .res_main{
        margin-top: 250px;
    }
    .resources{
   
        display: grid;
        grid-template-columns: auto  ;
        align-items: start;
       
    
      
    }
    .r_Activities-card1 {
        width: 60%;
        margin-left: 50px;
        margin-top: 25px;
    }
}
@media screen  and (max-width:350px) {
    .res_main{
        margin-top: 250px;
    }
    .resources{
   
        display: grid;
        grid-template-columns: auto  ;
        align-items: start;
        
       
    
      
    }
    .r_Activities-card1 {
        width: 80%;
        height: fit-content;
        margin-left: 25px;
        
    }
   
    

}