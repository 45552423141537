@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: local('LibreBaskerville-Bold'),
         url('../../fonts/LibreBaskerville-Bold.ttf') format('truetype');
       
}



.spl_heading{
    text-align: center;
    margin-top: 150px;
    margin-left: 60px;
    font-family: LibreBaskerville-Bold;
    color: rgb(100, 5, 68);
    font-weight:900;
   
}

.spl_Activities-card1 {
    
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: fit-content;
    text-align: justify;
    align-items: center;
    font-weight: 500;
    width: 90%;
    display: flex;
    flex-direction: row;
    background-image: url('../../images/orange4.jpg');
background-repeat: no-repeat;
background-size:cover;
padding: 20px;
margin-left: 50px;



 
}


/*.spl_Activities-card1:hover{
    transform: scale(1.05);   
  
}*/
.spl_card-body{
    
    margin-bottom: 50px;
   
   

}



.spl_card-img{
    height: 100%;
    width: 100%;
}
.spl_card-text{
    width: 80%;
    font-size:15px;
    margin-top: 30px;
    text-align: justify;
    
}
.spl_card-title{
    font-size: 20px;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 20px;
    
}
.spl_card-body2{
    width: 400px;
    height: 200px;
    
}
.spl_card-body2  img{
   width: 300px;
   height:100%;
 
    

    
}
.spl_cards{
    padding: 20px;
    margin-left: 50px;
    width: fit-content;
}
.Vspl_card-body2 img{
    width: 400px;
    height:80%;
    margin-left: 50px;
}
.Tspl_card-body2 img{
    width: 400px;
    height:350px;
    margin-left: 50px;
}
.Ospl_card-body2 img{
    width: 400px;
    height:290px;
    margin-left: 50px;
}
.k_spl_card-body2 img{
    width:400px;
    height:350px;
    margin-left: 50px;
}


@media screen  and (max-width:992px){
    .spl_cards{
        margin-left: 10px;
    }
    .spl_Activities-card1{

        display: flex;
        flex-direction: column;
        width: 80%;
        margin-left: 10px;

    }
    .spl_card-text{
        width: 70%;
        font-size:15px;
        margin-top: 30px;
        text-align: justify;
        
    }
    .spl_card-body2  img{
        width: 70%;
        height:100%;
        margin-left: 50px;
         
     
         
     }
     .k_spl_card-body2 img{
        margin-left: 5px;
        width: 100%;
     }
     .Ospl_card-body2 img{
        width: 100%;
        margin-left: 5px;
     }
     .Tspl_card-body2 img{
        margin-left: 5px;
        width: 95%;
     }
}