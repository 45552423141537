@font-face {
    font-family: 'BungeeSpice-Regular';
    src: local('BungeeSpice-Regular'),
         url('../../fonts/BungeeSpice-Regular.ttf') format('truetype');
       
}
@font-face {
    font-family: 'LibreBaskerville-Bold';
    src: local('LibreBaskerville-Bold'),
         url('../../fonts/LibreBaskerville-Bold.ttf') format('truetype');
       
}
@font-face {
    font-family: 'Mogra-Regular';
    src: local('Mogra-Regular'),
         url('../../fonts/Mogra-Regular.ttf') format('truetype');
       
}



@font-face {
    font-family: 'Cooper BlkHd BT Black';
    src: local('Cooper BlkHd BT Black'),
         url('../../fonts/Cooper BlkHd BT Black.ttf') format('truetype');
       
}



.Home-Container{
   
    position: relative;
    height: fit-content;
  
 

   
    
   
}

/* ---Main Heading part of Home page---*/



.Main-Heading{
    
    padding: 0;
    margin-top:10px;
    border-radius: 2vh;
    align-items: center;
    justify-content: center; 
  
}


.HomeHeading{

    align-items: center;
    font-family:LibreBaskerville-Bold;
    font-size:10px;
    margin-left: 300px;
    margin-top: 120px;
    color: rgb(5, 5, 100);
   
 
   
}
.Home_quote{
    margin-left: 300px;
    font-size: large;
    color: #e97b14;
    font-weight: 800;
}


/*Resposive head part */


@media screen  and (max-width:1200px)  {
   

    .Main-Heading{

        width:100%; 

    }
    
    .HomeHeading{

        font-size:x-large;
        margin-left: 150px;
      
       
    }
    .Home_quote{
        margin-left:140px ;
    }
    
}

@media  screen and (min-width: 992px) {

    .Home-Container{
        display: flex;
        margin-top: 5px;
        flex-direction: column;
        
    }

    .Main-Heading{

        width:100%; 

    }
    
    .HomeHeading{
       
        position: relative;
        width : 100%;
        font-size:50px;
      

       
    }
    
   
}
@media only screen and (min-width: 768px) {
    .Home-Container{
        display: flex;
        margin-top: 5px;
        flex-direction: column;
        
    }

    .Main-Heading{

        width:100%; 

    }
    
    .HomeHeading{
       
        position: relative;
        width : 100%;
        font-size:50px;
      

       
    }
}

@media only screen and (min-width: 600px) {
    .Home-Container{
        display: flex;
        margin-top: 5px;
        flex-direction: column;
        overflow: hidden;

        
    }

    .Main-Heading{

        width:100%; 

    }
    
    .HomeHeading{
       
        position: relative;
        width : 100%;
        font-size:45px;
      

       
    }
}

@media screen  and (min-width:300px) and (max-width:480px) {
    .Home-Container{
        display: flex;
        margin-top: 5px;
        flex-direction: column;
        
    }

    .Main-Heading{

        width:100%; 

    }
    
    .HomeHeading{
       
        position: relative;
        width : 100%;
        font-size:20px;
        margin-left: 22px;
        margin-top: 170px;
      

       
    }
    .Home_quote{
        margin-left: 30px;
        font-size: 14px;
        color: #e97b14;
        font-weight: 800;
    }
}



/*-------------------------------------*/





/*-------------------------------------*/






.marquee{
   
    background-color: rgb(32, 25, 100);
    font-size: 20px;
    position: absolute;
    font-family:'Times New Roman', Times, serif;
    font-weight: bold;
    margin-top: 200px;
   
    color:white;
    z-index: 0.5;

    
   
   
  }
  
  
  .blink{
      background-color:rgb(32, 25, 100);
      animation: blink 1s linear infinite;
     
      font-size: 20px;
      position:relative;
      font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: bold;
      margin-top: 90px;
      padding: 10px 0;
      overflow: hidden;
      color:white;
      right: 0;
   
  }
  
  
  
  
  .marquee img{
    float: left;
    width: 100px;
    height: 100px;
    background: #555;
  
  }
 



@media only screen and (max-width: 480px){
      
.marquee p{
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    
}
.blink{
    font-size: 12px;
}
}
/* ---Join Us part of Home page---*/

.Home_container2{

  /*  display: flex;
    flex-direction: row;*/
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(480px,1fr));
    margin-left: 50px;
    
   
}

.Join_Us_part{

    position: relative;
    border-style:solid;
    border-color: black;
    width:70%;
    border-radius: 2vh;
    align-items: center;
   
  
  

}

.Para_bg{

    position: absolute;
  
}

.Para_1{

   position: relative; 
   padding:20px;
   color: rgb(5, 5, 100); 
   font-weight:500;
   font-size: medium;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   width: 600px;
   height: 400px;
   text-align: justify; 
   background-image: url('../../images/para_bg_200.jpg');
   background-repeat: no-repeat;
   background-size: cover;

}

.Para_1 img{

   width: 800px;
   height: 380px;
   margin-left: 50px;
   border-radius: 15px;
    
  

}

.Para_bg p{

    padding: 50px;
    margin-left:50px;

}

.JoinUs_Btn_Div{
   background-color: #666;
   
}

.JoinUs_Button{

  display: inline-block;
  /*padding: 15px 25px;*/
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  background-color:  rgb(17, 10, 107);
  border-radius: 15px;
  margin-left: 900px;
  
  width: 110px;
  height: 40px;
  
  padding:8px;
 
}

.JoinUs_Button:hover {background-color:rgb(9, 39, 124)}

.JoinUs_Button:active {

  background-color: #97aae0;
  box-shadow: 0 2px #666;
  transform: translateY(4px);

}

.Krishna_Image_img{

    float:left;
    width:400px;
    border-radius: 30px;
    padding: 10px;   
    margin-left: 50px;
}

@media screen  and (max-width:1200px) {
    
    
    .JoinUs_Button{
        
        margin-left: 400px;
    }
    
   
}
@media screen  and (max-width:992px) {
    
   
    .JoinUs_Button{
        
       
        position:absolute;
        margin-left: 300px;

    }
   

   
}

@media screen  and (max-width:768px) {
    
    .JoinUs_Button{
        
       
        position:absolute;
        margin-left: 200px;

    }
    

}
@media screen  and (max-width:600px) {

    
    .JoinUs_Button{
        
       
         position: absolute;
        margin-left: 200px;

    }
    

}



@media only screen and (max-width: 480px) {
    .Join_Us_part{
        margin-right: 0;
        width:90%;
    }
    .Krishna_Image_img{
        margin-left: 15px;
        width: 80%;
    }
    .Home_container2{
        display: flex;
        flex-direction: column;
    }
    .JoinUs_Button{
        
       
       
        margin-left: 90px;
        font-size: 13px;
        
        

    }
    .Para_1{
        margin-top: 10px;
        width:100%;
       
        font-size:10.5px;
        margin-bottom: 10px;
        padding: 0;
    }
    .Para_bg p{
        padding: 40px;
        margin-left: 2px;
        height: 50%;
        width: 100%;
        
    
    }
 
     
  
}


.Activities-div{
    margin-top: 70px;
    width: 100%;
    text-align: center;
   
}

.cards{
    width: fit-content;
    text-align: center;
    display:flex ;
    flex-direction:row;
    margin-left: 50px;
    margin-top: 50px;
    

}
/*'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif*/
.Activities-card1 {

    padding:30px;
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    height: fit-content;
    margin:30px;
    text-align: justify;
    align-items: center;
    font-weight: 500;
    background-image: url('../../images/card_bg_6.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    
  
}

.Activities-card1 a{

    margin-left: 100px;
    width: 100px;
    height: 40px;
    text-align: center;
    font-size: 15px;
}

.Activities-card1:hover{

    transform: scale(1.05);   
    cursor: pointer;
 
}

.Activities-card-intro{

    text-align: center;

}

.Activities-card-intro h1{

    font-family:  LibreBaskerville-Bold;
    font-weight: bold;
    font-size: 30px;
    background-image: url('../../images/orange4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 100px;
    border-radius: 20%;
    align-items: center;
    margin-left: 400px;
    padding: 30px;
   
}

.Activities-card-intro p{
    
    color: rgb(5, 5, 100);
    margin: 20px;
    font-weight:500;
    font-size: medium;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 90%;
    text-align: justify;
    margin-left: 50px;
}

.card-img{

    height: 100%;
    width: 100%;

}

.card-text{

    width: 100%;
    font-size:15px;
    margin-top: 10px;
    
}

.card-title{

    font-size: 20px;
    text-align: center;
    font-weight: bolder;
    margin-top: 15px;

}



@media screen  and (max-width:1200px) {
   

    .Activities-div{
        margin-top: 5px;
    }
    .Activities-card1{
        width: 50%;
     }

    .cards{
        display: flex;
        flex-direction: column;
        margin-left: 300px;
       
    }

    .Activities-card-intro p{
        width: 90%;
        margin-left: 10px;
        font-size: small;
    }
    .Activities-card-intro h1{
        margin-left: 50px;
        width: 80%;
        height: 60%;
        font-size: large;
        padding: 10px;
    }
}

@media screen  and (max-width:786px){
    .Activities-div{
        margin-top: 50px;
       
       }
       .Activities-card1{
        width: 80%;
     }

    .cards{
        display: flex;
        flex-direction: column;
        margin-left: 200px;
       
    }

    .Activities-card-intro p{
        width: 90%;
        margin-left: 100px;
    }
    .Activities-card-intro h1{
        margin-left: 100px;
    }
}

@media screen  and (max-width:480px){
    .Activities-div{
        margin-top: 100px;
        
        
    }
 .Activities-card1{
    width: 80%;
 }

    .cards{
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        width: 100%;
        
        

       
    }
    .card-text{
        font-size: 12px;
    }

    .Activities-card-intro p{
        width: 80%;
        margin-left: 35px;
        
    }
    .Activities-card-intro h1{
        margin-left: 30px;
    }
}
    




.ImageSlider{

  width:100%;
  position: relative;
 margin-top: 130px;
}


.carousel{

    margin-left: 25px;
    margin-top: 150px;
    width: 90%;
    height:500px; 
    white-space: nowrap;
    transition: ease 1000ms;
    
}


.carousel_text{
    position: absolute;
    color:white;
    border: 1px solid #fff ;
    width:750px;
    height: 450px;
    margin-left: 30px;
    margin-top: 30px;
    text-align: left;
 
}
.carousel_text h2{
 margin-top: 100px;
}

@media screen  and (max-width:1023px){
    .ImageSlider{
        display: flex;
        flex-direction: column;
        margin-top: 200px;
        width:95%;
    }
    .carousel{
        width:90%;
        margin:15%;
        margin-top: 250px;
        margin-left: 50px;
        
      }
     
}

@media screen  and (max-width:789px){
    .ImageSlider{
        display: flex;
        flex-direction: column;
        margin-top: 200px;
        width:50%;
    }
    .carousel{
        width:90%;
        margin:15%;
        margin-top: 100px;
        margin-left: 25px;
        
      }
      .Youtube_video iframe{
        width: 70%;
      }
   
     
}

@media screen  and (max-width:300px){
    .ImageSlider{
        display: flex;
        flex-direction: column;
        margin-top: 200px;
        width:40%;
    }
    .carousel{
        width:90%;
        margin:15%;
        margin-top: 100px;
        margin-left: 10px;
        
      }
      .Youtube_video iframe{
        width: 60%;
        margin-left: 5px;
      }
   
    }






.Youtube_video iframe{
 margin-left: 50px;
 width: 100%;
 margin-top: 20px;
    


}

.Event_div{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(480px,1fr));
   
  
}

.Events_Cards{
    display: flex;
    flex-direction: column;
 
}
.Events_Cards  td{
    font-size: 15px;
}
.Upcoming{
    width:100%;
    height: fit-content;
    margin-left:170px;
    align-items: center;
    margin-top:20px;
    
}

.Upcoming table ,th,td{
  
  padding: 20px;
     
}

.upcoming_table{
    width:50%;
   border: 2px solid orange;
 
}
.Date_Column{

    font-weight: bolder;
    width:30%;
    text-align: center;
   
}
.upcoming-heading{
    text-align: center;
    background-color: rgb(209, 67, 11);
    color: white;
   
}

.event_h{
    margin-left: 150px;
    margin-top: 110px;
}


.Recent{
    width:70%;
    height: fit-content;
    margin-left:150px;
    align-items: center;
    margin-top:20px;
}
.recent-heading{
    text-align: center;
    background-color: rgb(233, 145, 44);
    color: white;

}
.recent_table{
    width:90%;
    border: 2px solid rgb(241, 97, 0);
}
.recent_table th,td{
    padding: 20px;
}
.recent_button{
    background-color: white;
    color: #e97b14;
    font-weight:bold;
}

.Recent h4{
    margin-top: 10px;
}

@media screen  and (max-width:1200px){

    .Event_div{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
    }
      
        .Events_Cards{
    
      display: flex;
      flex-direction: column;
    
        }
        .Upcoming{
            width:60%;
            margin-left: 200px;
          
        }
        .upcoming_table{
            width:50%;
            font-size: 12px;
        }
        .Recent{
            margin-left: 200px;
            
        }
        
       
    }
@media screen  and (max-width:480px){
    .Event_div{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
        

    }
    .Upcoming{
        width:60%;
        margin-left: 10px;
      
    }
    .upcoming_table{
        width:50%;
        font-size: 12px;
    }
    .Recent{
        margin-left: 10px;
        width: 70%;
    }
}



.Table-cards{

    width: 80%;
    height: 80%;
    margin-top: 100px;
    padding: 20px;

 
}
.Table-cards table{
 
    margin-left: 200px;
    padding: 20px;
    

   
}
.Table-cards td{
   

    font-family:Mogra-Regular;
    font-size:xx-large;
    margin:50px;
    width: 500px;
    text-align: center;
    height: 300px;
    
    
    
}

.focus-month-img{
    width:35%;
    height: 95px;
    margin-left: 50px;
    border-radius: 5px;
}
.resource-img{

    width:40%;
    margin-left: 30px;
}

.focus_month{
 background-color: rgba(40, 151, 185, 0.479);
 width: 200px;
 height: 350px;

}
.resource{
    background-color: #f32f8a70;
}



.Table-cards a:active {
  box-shadow: 0 2px #666;
  transform: translateY(10px);
}

@media screen  and (max-width:480px){
    .Table-cards{

        width: 60%;
        margin-top: 100px;
        padding: 20px;
    
     
    }
    .Table-cards table{
     
        margin-left: 15px;
        padding: 20px;
        
    
       
    }.Table-cards td{
        height: 50%;
    }
}
@media screen  and (max-width:480px){
    .Table-cards{

        width: 60%;
        margin-top: 100px;
        padding: 20px;
    
     
    }
    .Table-cards table{
     
        margin-left: 25px;
        padding: 20px;
        
    
       
    }.Table-cards td{
        height: 40%;
        width: 50%;
    }
}


.Recent{
    
}







.modal_div{

  align-items:center;
 
}
.modal_bg_img{
    width: 100%;
    margin-left: 80px;
}
.modal_heading{
    font-family: LibreBaskerville-Bold;
    color: rgb(5,5,100);
    font-weight:900;
}
.dn_bt_modal{
  
    
    background-color: white;
     color: #e97b14;
     font-weight:bold;
   
}
.dt_bt_modal{
   
   
   
    background-color: white;
     color: #e97b14;
     font-weight:bold;
}
.modal_btns{
    display: flex;
    flex-direction: row;
}