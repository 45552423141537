.recent{
    padding: 50px;
}
.recent_div h1{
    margin-left: 100px;
}
.recent_div{
    
    height: fit-content;
    width: 100%;
    border: 2px solid orange;
    padding: 20px;
   margin-top: 150px;
}

.recent_h{
    margin-top: 120px;
    text-align: center;
    margin-left: 60px;
}

.recent_div2{
   
   
    display: flex;
    flex-direction: row;

}
.recent_div3{
    text-align: justify;
    width: 50%;
    padding: 50px;
}
.recent_div4_img{
    width:300px;
    margin-left: 100px;
    margin-top:50px;
}
.recent_div4_img1{
    width: 400px;
    margin-left: 50px;
    margin-top:30px;
}
@media screen  and (max-width:600px)
{
    .recent_div {
        margin-top: 200px;
        
    }
    
    .recent_div3{
        text-align: justify;
        width: 90%;
        padding: 10px;
    }
    .recent_div2{
   
   
        display: flex;
        flex-direction: column;
    
    }
    .recent_div4 img{
        width:80%;
        margin: 30px;
    }
    .recent_div h1{
        margin-left: 20px;
        font-size: 18px;
    }
    .recent_div p{
        font-size: 13px;
    }
}